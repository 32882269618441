import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
  useCallback,
} from "react";
import {
  ICheckout,
  ICheckoutTotals,
  ICustomerAddress,
  IShippingAddress,
  IShippingRate,
  IPickupLocation,
} from "./types/types";
import createCheckoutFromCart from "./utils/createCheckoutFromCart";
import {useParams, useSearchParams} from "react-router-dom";
import config from "./utils/config";
import { storefrontSdk } from "./utils/sdk";
import {
  CartBuyerIdentityInput,
  GetCartQuery,
  CountryCode,
} from "./types/storefront.generated";
import { postcodeToRegion } from "./utils/postcodeToRegion";

import HyperDX from '@hyperdx/browser';

HyperDX.init({
  apiKey: process.env.REACT_APP_HYPERDX_API_KEY ?? '',
  service: `checkout-frontend-${config.environment}`,
  tracePropagationTargets: [/checkout.bushbuck.dev/i],
  consoleCapture: true,
});

interface CartContextValues {
  checkout: ICheckout | null;
  cartLoading: boolean;
  totalsLoading: boolean;
  checkoutTotals: ICheckoutTotals | null;
  shippingRates: IShippingRate[] | null;
  pickupLocations: IPickupLocation[] | null;
  addDiscountCode: (discountCode: string) => Promise<void>;
  addGiftCard: (GiftCardCode: string) => Promise<void>;
  removeGiftCard: () => Promise<void>;
  updateEmail: (email: string | null) => Promise<void>;
  updateCustomerPreferredAddress: (
    address: ICustomerAddress | null,
  ) => Promise<void>;
  updateShippingAddress: (
    email: string | null,
    phone: string | null,
    address: IShippingAddress | null,
  ) => Promise<void>;
  removeUserFromCart: () => Promise<void>;
  updateShippingMethod: (groupId: string, handle: string) => Promise<void>;
  toggleEmailMarketingOptIn: () => void;
}

const CartContext = createContext<CartContextValues | undefined>(undefined);

interface CartProviderProps {
  children: ReactNode;
}

export const CartProvider: React.FC<CartProviderProps> = ({ children }) => {
  const [cart, setCart] = useState<GetCartQuery["cart"] | null>(null);
  const [checkout, setCheckout] = useState<ICheckout | null>(null);
  const [checkoutTotals, setCheckoutTotals] = useState<ICheckoutTotals | null>(
    null,
  );
  const [shippingRates, setShippingRates] = useState<IShippingRate[] | null>(null);
  const [pickupLocations, setPickupLocations] = useState<IPickupLocation[] | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [totalsLoading, setTotalsLoading] = useState(false);
  const [cartLoading, setCartLoading] = useState(false);
  const queryParams = useParams();
  const [searchParams] = useSearchParams();
  const cartKey = searchParams.get('key');
  const cartId = `${queryParams.cartId}?key=${cartKey}`;
  if (cartId) {
    HyperDX.setGlobalAttributes({
      cartId: `gid://shopify/Cart/${cartId}`,
      environment: config.environment,
      countryCode: config.countryCode,
    });
    HyperDX.addAction('Checkout Started', {
      cartId: `gid://shopify/Cart/${cartId}`,
    });
  }
  const storefront = storefrontSdk();

  const fetchOrderTotals = useCallback(
    async (cartId: string) => {
      if (error || cartLoading) {
        return;
      }
      try {
        setTotalsLoading(true);
        const response = await fetch(
          `${config.checkoutUrl}/api/calculateOrder`,
          {
            method: "POST",
            body: JSON.stringify({
              cartId: cartId,
            }),
          },
        );
        const { totals } = await response.json();
        setCheckoutTotals(totals);
      } catch (e) {
        console.error(e);
        setError(e);
      } finally {
        setTotalsLoading(false);
      }
    },
    [cartLoading, error],
  );

  const fetchPickupLocations = useCallback(async () => {
    setPickupLocations([]);
    // try {
    //   const response = await storefront.getLocations();
    //
    //   if (!response.locations?.nodes?.length) {
    //     setPickupLocations([]);
    //     return;
    //   }
    //
    //   // Without fulfillmentService, we need another way to identify pickup locations
    //   // This assumes all locations support pickup or you have some way to identify them
    //   const locations = response.locations.nodes
    //     .map(location => ({
    //       id: location.id,
    //       title: `Pickup at ${location.name}`,
    //       price: { amount: '0.00', currencyCode: config.currencyCode },
    //       handle: `pickup-${location.id}`.toLowerCase().replace(/\s+/g, '-'),
    //       address: location.address
    //     }));
    //
    //   // setPickupLocations(locations);
    // } catch (e) {
    //   console.error('Error fetching pickup locations:', e);
    //   setPickupLocations([]);
    // }
  }, []);

  const processShippingRates = useCallback((cart: GetCartQuery['cart']) => {
    if (!cart) return;

    const doesntRequireShipping = cart.lines.nodes.every(line =>
      line.__typename === 'CartLine' && line.merchandise?.requiresShipping === false
    );

    if (doesntRequireShipping) {
      setShippingRates([{
        title: 'Digital Delivery',
        price: { amount: '0.00', currencyCode: config.currencyCode },
        handle: 'digital-delivery'
      }]);
      return;
    }

    // Extract shipping rates from delivery groups
    const deliveryOptions = cart.deliveryGroups?.nodes?.flatMap(group =>
      group.deliveryOptions?.map(option => ({
        title: option.title ?? 'Standard Shipping',
        description: option.description || undefined,
        price: option.estimatedCost || { amount: '9.99', currencyCode: config.currencyCode },
        handle: option.handle,
        deliveryGroupId: group.id,
      })) || []
    ) || [];

    setShippingRates(deliveryOptions);
  }, []);

  const updateEmail = useCallback(
    async (email: string | null) => {
      if (!checkout?.cartId) {
        console.error("Invalid cart ID");
        return;
      }
      if (email) {
        HyperDX.setGlobalAttributes({
          userEmail: email
        });
        HyperDX.addAction('Email set', {
          userEmail: email
        });
      }
      try {
        let buyerIdentity: CartBuyerIdentityInput = {};
        if (checkout?.phone) {
          buyerIdentity.phone = checkout.phone;
        }

        buyerIdentity.email = email;
        const cartData = await storefront.updateCartBuyerIdentity({
          buyerIdentity,
          cartId: checkout.cartId,
        });
        if (cartData.cartBuyerIdentityUpdate?.cart) {
          const checkoutData = await createCheckoutFromCart(
            cartData.cartBuyerIdentityUpdate.cart,
          );
          setCheckout(checkoutData);
          setCart(cartData.cartBuyerIdentityUpdate.cart);
          processShippingRates(cartData.cartBuyerIdentityUpdate.cart);
        }
      } catch (e) {
        throw e;
      } finally {
        setCartLoading(false);
      }
    },
    [
      processShippingRates,
      checkout,
      storefront,
    ],
  );

  const fetchCart = useCallback(
    async (cartId: string) => {
      try {
        setCartLoading(true);
        const storefront = storefrontSdk();
        const cartData = await storefront.getCart({
          cartId: "gid://shopify/Cart/" + cartId,
        });
        const buyerIdentity = cartData.cart?.buyerIdentity;
        const checkout = await createCheckoutFromCart(cartData.cart);
        setCheckout(checkout);
        setCart(cartData.cart);

        // Process shipping rates from the cart
        processShippingRates(cartData.cart);

        // Fetch pickup locations if needed
        await fetchPickupLocations();

        if (!buyerIdentity?.email && buyerIdentity?.customer?.email) {
          await updateEmail(buyerIdentity?.customer?.email);
        }

      } catch (e) {
        console.error(e);
        throw e;
      } finally {
        setCartLoading(false);
      }
    },
    [updateEmail, processShippingRates, fetchPickupLocations],
  );

  useEffect(() => {
    if (cartId && !cart) {
      fetchCart(cartId).catch((error) => {
        setError(error);
      });
    }
  }, [cart, cartId, fetchCart]);

  useEffect(() => {
    if (!checkout?.cartId) {
      return;
    }
    fetchOrderTotals(checkout.cartId ?? "").catch((error) => {
      setError(error);
    });
  }, [checkout, fetchOrderTotals]);

  const addDiscountCode = async (discountCode: string) => {
    if (!checkout?.cartId) {
      throw new Error("Invalid cart ID");
    }
    setCartLoading(true);
    try {
      const cartData = await storefront.applyDiscountCodeToCart({
        cartId: checkout.cartId,
        discountCodes: discountCode,
      });

      if (cartData.cartDiscountCodesUpdate?.cart) {
        const appliedCode =
          cartData.cartDiscountCodesUpdate.cart.discountCodes?.find(
            (code) => code.code === discountCode,
          );
        const isPartnerCode = config.partnerCodes.includes(appliedCode?.code?.toUpperCase() ?? '');
        if (discountCode !== "" && !isPartnerCode && !appliedCode?.applicable) {
          throw new Error("Invalid discount code");
        }
        const checkoutData = await createCheckoutFromCart(
          cartData.cartDiscountCodesUpdate.cart,
        );
        setCheckout(checkoutData);
        setCart(cartData.cartDiscountCodesUpdate?.cart);
        processShippingRates(cartData.cartDiscountCodesUpdate?.cart);
      }
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      setCartLoading(false);
    }
  };

  const addGiftCard = async (giftCardCode: string) => {
    if (!checkout?.cartId) {
      throw new Error("Invalid cart ID");
    }
    setCartLoading(true);
    try {
      await storefront.updateGiftCard({
        cartId: checkout.cartId,
        giftCardCodes: [giftCardCode]
      });

      // Fetch the updated cart
      await fetchCart(cartId);
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      setCartLoading(false);
    }
  };

  const removeGiftCard = async () => {
    if (!checkout?.cartId) {
      throw new Error("Invalid cart ID");
    }
    setCartLoading(true);
    try {
      // Find any applied gift cards to remove
      const appliedGiftCardIds = cart?.appliedGiftCards?.map(card => card.id) || [];

      if (appliedGiftCardIds.length === 0) {
        console.warn("No gift cards to remove");
        return;
      }

      const response = await storefront.removeGiftcard({appliedGiftCardIds, cartId: checkout.cartId});

      if (response.cartGiftCardCodesRemove?.userErrors?.length) {
        const errorMessage = response.cartGiftCardCodesRemove.userErrors[0].message;
        console.error("Error removing gift card:", errorMessage);
        throw new Error(errorMessage);
      }

      // Fetch the updated cart
      await fetchCart(cartId);

      // Update totals after gift card removal
      await fetchOrderTotals(checkout.cartId);
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      setCartLoading(false);
    }
  };

  const updateShippingAddress = useCallback(
    async (
      email: string | null,
      phone: string | null,
      shippingAddress: IShippingAddress | null = null,
    ) => {
      if (!checkout) {
        return;
      }
      setCartLoading(true);

      try {
        if (email || phone) {
          let buyerIdentity: CartBuyerIdentityInput = {};
          if (email) {
            buyerIdentity.email = email;
          }
          if (phone) {
            buyerIdentity.phone = phone;
          }

          await storefront.updateCartBuyerIdentity({
            buyerIdentity,
            cartId: checkout.cartId,
          });
        }

        // Then update delivery address using the appropriate API
        if (shippingAddress) {
          if (shippingAddress?.zip) {
            const region = postcodeToRegion(shippingAddress.zip);
            shippingAddress.provinceCode = region.code;
          }

          const deliveryAddress = {
            firstName: shippingAddress?.firstName || '',
            lastName: shippingAddress?.lastName || '',
            address1: shippingAddress?.address1 || '',
            address2: shippingAddress?.address2 || '',
            city: shippingAddress?.city || '',
            provinceCode: shippingAddress?.provinceCode || '',
            zip: shippingAddress?.zip || '',
            countryCode: shippingAddress?.country as CountryCode || '',
            phone: shippingAddress?.phone || ''
          };

          const cartDeliveryAddressResponse = await storefront.addDeliveryAddress({
            cartId: checkout.cartId,
            addresses: [
              {
                address: { deliveryAddress },
                selected: true
              }
            ]
          });

          if (cartDeliveryAddressResponse.cartDeliveryAddressesAdd?.cart) {
            const checkoutData = await createCheckoutFromCart(
              cartDeliveryAddressResponse.cartDeliveryAddressesAdd.cart
            );
            setCheckout(checkoutData);
            setCart(cartDeliveryAddressResponse.cartDeliveryAddressesAdd.cart);
            processShippingRates(cartDeliveryAddressResponse.cartDeliveryAddressesAdd.cart);
          }
        }
      } catch (e) {
        console.error("Error updating shipping address:", e);
        throw e;
      } finally {
        setCartLoading(false);
      }
    },
    [checkout, storefront, processShippingRates],
  );

  const updateCustomerPreferredAddress = useCallback(
    async (customerAddress: ICustomerAddress | null = null) => {
      if (!checkout?.cartId || !customerAddress) {
        return;
      }
      setCartLoading(true);

      try {
        const cartDeliveryAddressResponse = await storefront.addDeliveryAddress({
          cartId: checkout.cartId,
          addresses: [
            {
              address: {
                copyFromCustomerAddressId: customerAddress.id
              },
              selected: true,
            }
          ]
        });

        if (cartDeliveryAddressResponse.cartDeliveryAddressesAdd?.cart) {
          const checkoutData = await createCheckoutFromCart(
            cartDeliveryAddressResponse.cartDeliveryAddressesAdd.cart
          );
          setCheckout(checkoutData);
          setCart(cartDeliveryAddressResponse.cartDeliveryAddressesAdd.cart);
          processShippingRates(cartDeliveryAddressResponse.cartDeliveryAddressesAdd.cart);
        }
      } catch (e) {
        throw e;
      } finally {
        setCartLoading(false);
      }
    },
    [checkout?.cartId, storefront, processShippingRates],
  );

  const updateShippingMethod = useCallback(
    async (groupId: string, handle: string) => {
      if (!checkout?.cartId) {
        throw new Error("Invalid cart ID");
      }
      setCartLoading(true);
      try {
        const cartData = await storefront.updateDeliveryAddress({
          cartId: checkout.cartId,
          selectedDeliveryOptions: [
            {
              deliveryGroupId: groupId,
              deliveryOptionHandle: handle
            }
          ]
        });

        if (cartData.cartSelectedDeliveryOptionsUpdate?.cart) {
          const checkoutData = await createCheckoutFromCart(
            cartData.cartSelectedDeliveryOptionsUpdate.cart
          );
          setCheckout(checkoutData);
          setCart(cartData.cartSelectedDeliveryOptionsUpdate.cart);
          processShippingRates(cartData.cartSelectedDeliveryOptionsUpdate.cart);
        }
      } catch (e) {
        throw e;
      } finally {
        setCartLoading(false);
      }
    },
    [checkout?.cartId, storefront, processShippingRates],
  );

  const removeUserFromCart = useCallback(async () => {
    if (!checkout) {
      return;
    }
    setCartLoading(true);
    let buyerIdentity: CartBuyerIdentityInput = {
      customerAccessToken: null,
    };

    try {
      const cartData = await storefront.updateCartBuyerIdentity({
        buyerIdentity,
        cartId: checkout.cartId,
      });
      if (cartData.cartBuyerIdentityUpdate?.cart) {
        const checkoutData = await createCheckoutFromCart(
          cartData.cartBuyerIdentityUpdate.cart,
        );
        setCheckout(checkoutData);
        setCart(cartData.cartBuyerIdentityUpdate.cart);
        processShippingRates(cartData.cartBuyerIdentityUpdate.cart);
      }
    } catch (e) {
      throw e;
    } finally {
      setCartLoading(false);
    }
  }, [checkout, storefront, processShippingRates]);

  const toggleEmailMarketingOptIn = () => {
    if (!checkout) {
      return;
    }
    setCheckout({
      ...checkout,
      emailMarketingOptIn: !checkout.emailMarketingOptIn,
    });
  };

  useEffect(() => {
    if (!checkout?.shippingAddress && checkout?.customer?.preferredAddress) {
      updateCustomerPreferredAddress(checkout.customer.preferredAddress);
    }
  }, [checkout?.shippingAddress, checkout?.customer?.preferredAddress, updateCustomerPreferredAddress]);

  if (error) {
    // Display the error component if an error occurred
    return (
      <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8 mt-10">
        <div className="text-center">
          <p className="text-base font-semibold text-gray-600">Whoops!</p>
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            Something went wrong
          </h1>
          <p className="mt-6 text-base leading-7 text-gray-600">
            Sorry, we were unable to retrieve the checkout details. The cart may
            be expired
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <a
              href={config.storeUrl}
              className="rounded-md bg-gray-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
            >
              Back to store
            </a>
            <a
              href={`${config.storeUrl}/pages/contact-us`}
              className="text-sm font-semibold text-gray-900"
            >
              Contact support <span aria-hidden="true">&rarr;</span>
            </a>
          </div>
        </div>
      </main>
    );
  }

  const contextValues: CartContextValues = {
    checkout,
    checkoutTotals,
    shippingRates,
    pickupLocations,
    addDiscountCode,
    addGiftCard,
    removeGiftCard,
    updateCustomerPreferredAddress,
    updateShippingAddress,
    updateEmail,
    updateShippingMethod,
    removeUserFromCart,
    cartLoading,
    totalsLoading,
    toggleEmailMarketingOptIn,
  };

  return (
    <CartContext.Provider value={contextValues}>
      {children}
    </CartContext.Provider>
  );
};

export const useCartContext = () => {
  const context = useContext(CartContext);

  if (!context) {
    throw new Error("useCartContext must be used within a CartProvider");
  }

  return context;
};
