import React, {useState, useEffect, Fragment} from "react";
import config from "./utils/config";
import formatPrice from "./utils/formatPrice";
import { useCartContext } from "./CartContext";
import { IShippingAddress } from "./types/types";
import SvgLogo from "./SvgLogo";
import LoadingSpinner from "./components/LoadingSpinner";
import StaticMap from "./components/StaticMap";
import { useLocation } from "react-router-dom";
import BundleItem from "./components/BundleItem";
import LineItem from "./components/LineItem";
import { getPaymentRequestFromQueryParams } from "./utils/getPaymentRequestFromQueryParams";
import trackingService from "./analytics/trackingService";
import GiftItem from "./components/GiftItem";
import FreeGift from "./components/FreeGift";
import {Transition} from "@headlessui/react";
import LineItemMobile from "./components/LineItemMobile";
import BundleItemMobile from "./components/BundleItemMobile";
import GiftItemMobile from "./components/GiftItemMobile";
import FreeGiftMobile from "./components/FreeGiftMobile";

interface IPaymentMethodCard {
  brand: string;
  last4?: string;
}

export interface IPaymentMethodData {
  paymentMethod: {
    card: IPaymentMethodCard | null;
  };
  shippingAddress: Partial<IShippingAddress> | null;
}

const Confirm = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [paymentData, setPaymentData] = useState<IPaymentMethodData | null>(
    null,
  );
  const [showDetails, setShowDetails] = useState(false);
  const { checkout, checkoutTotals, totalsLoading } = useCartContext();

  useEffect(() => {
    const fetchPaymentData = async () => {
      if (!checkout?.cartId) {
        return;
      }
      const queryParams = new URLSearchParams(location.search);
      const paymentRequestData = getPaymentRequestFromQueryParams(queryParams);
      const response = await fetch("/api/getPaymentData", {
        method: "POST",
        body: JSON.stringify({
          ...paymentRequestData,
          cartId: checkout.cartId,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to retrieve payment data");
      }

      const orderData = await response.json();
      setPaymentData(orderData.data);
    };

    try {
      fetchPaymentData();
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [checkout?.cartId, location.search]);

  useEffect(() => {
    const trackPurchaseEvent = async () => {
      if (!checkout || !checkoutTotals) {
        return;
      }
      await trackingService.trackEvent("purchase", {
        checkout: checkout,
        totals: checkoutTotals,
      });
    };

    trackPurchaseEvent();
  }, [checkout, checkoutTotals]);

  if (!checkout) {
    // @TODO show loading spinner or error message?
    return <></>;
  }

  function formatAddress(address: Partial<IShippingAddress>): string {
    const parts: (string | undefined | null)[] = [
      address.address1,
      address.address2,
      address.city,
      address.province,
      address.country,
      address.zip,
    ];

    return parts
      .filter((part) => part !== undefined && part !== null)
      .join(", ");
  }

  return (
    <div className="bg-white">
      <div
        className="fixed left-0 top-0 hidden h-full w-1/2 bg-gray-50 lg:block"
        aria-hidden="true"
      />
      <div
        className="fixed right-0 top-0 hidden h-full w-1/2 bg-white lg:block"
        aria-hidden="true"
      />
      <div className="fixed inset-x-0 top-0 flex flex-col z-[101] text-sm font-medium text-gray-900 lg:hidden">
        <div className="relative z-[102] border-t border-gray-200 bg-white px-4 sm:px-6">
          <div className="mx-auto">
            <div className="flex w-full items-center py-4 font-medium focus:outline-0">
              <div className="mr-auto text-base">
                <div className="flex items-center justify-flex-start text-lg font-bold text-gray-500">
                  <a
                    href={`${config.storeUrl}/`}
                    aria-label="Bushbuck Logo"
                    className={`flex items-center justify-center cursor-pointer text-gray-900 h-6 ${showDetails && "pointer-events-none"}`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className={`w-5 h-5 mr-2 text-gray-400 transition ${showDetails && "-translate-x-4 opacity-0"}`}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                      />
                    </svg>
                    <span
                      className={`text-sm transition ${showDetails && "opacity-0"}`}
                    >
                          Back
                        </span>
                  </a>
                </div>
              </div>
              <button
                type="button"
                onClick={() => setShowDetails(!showDetails)}
                className="flex items-center mr-2 text-sm text-gray-400 underline decoration-dotted"
              >
                    <span className="mr-2">
                      {showDetails ? "Close" : "Details"}
                    </span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className={`w-3 h-3 transition-all transform ${showDetails && "rotate-180"}`}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>

        <Transition.Root as={Fragment} show={showDetails}>
          <div>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div
                onClick={() => setShowDetails(false)}
                className="fixed inset-0 bg-black bg-opacity-25"
              />
            </Transition.Child>

            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-y-full"
              enterTo="translate-y-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-y-0"
              leaveTo="-translate-y-full"
            >
              <div className="relative bg-white px-4 py-6 sm:px-6 z-[101] overflow-y-scroll">
                <div className="max-w-lg mx-auto mb-8">
                  <ul className="-my-6 divide-y divide-gray-200">
                    {checkout?.lineItems.map((line) => (
                      <LineItemMobile
                        key={line.variantId}
                        lineItem={line}
                        currencyCode={checkout.currencyCode}
                      />
                    ))}
                    {checkout.bundleItems.map((bundle) => (
                      <BundleItemMobile
                        key={bundle.id}
                        bundle={bundle}
                        currencyCode={checkout.currencyCode}
                      />
                    ))}
                    {checkout?.giftItems.map((giftItem) => (
                      <GiftItemMobile
                        key={giftItem.id}
                        giftItem={giftItem}
                        currencyCode={checkout.currencyCode}
                      />
                    ))}
                    {checkout?.freeGiftTier && checkoutTotals && (parseFloat(checkoutTotals.total) > checkout?.freeGiftTier.thresholdAmount || checkout?.freeGiftTier.isProductGift) && (
                      <FreeGiftMobile
                        freeGift={checkout?.freeGiftTier}
                        currencyCode={checkout.currencyCode}
                      />
                    )}
                  </ul>
                </div>
                {checkoutTotals && (
                  <dl className="mx-auto max-w-lg space-y-6 mt-6 border-t border-gray-200 pt-6">
                    <div className="flex items-center justify-between">
                      <dt className="text-gray-600">Cart Total</dt>
                      <dd>
                        {formatPrice(
                          checkoutTotals.carttotal,
                          checkout.currencyCode,
                        )}
                      </dd>
                    </div>
                    <div className="flex items-center justify-between">
                      <dt className="text-gray-600">Savings</dt>
                      <dd>
                        {formatPrice(
                          checkoutTotals.discounts,
                          checkout.currencyCode,
                        )}
                      </dd>
                    </div>
                    <div className="flex items-center justify-between">
                      <dt className="text-gray-600">Subtotal</dt>
                      <dd>
                        {formatPrice(
                          checkoutTotals.subtotal,
                          checkout.currencyCode,
                        )}
                      </dd>
                    </div>
                    <div className="flex items-center justify-between">
                      <dt className="text-gray-600">Shipping</dt>
                      <dd>
                        {formatPrice(
                          checkoutTotals.shipping,
                          checkout.currencyCode,
                        )}
                      </dd>
                    </div>
                    <div className="flex items-center justify-between">
                      <dt className="text-gray-600">Total</dt>
                      <dd>
                        {formatPrice(
                          checkoutTotals.total,
                          checkout.currencyCode,
                        )}
                      </dd>
                    </div>
                    <div className="text-sm text-gray-700 font-light">
                      including{" "}
                      {formatPrice(
                        checkoutTotals.tax,
                        checkout.currencyCode,
                      )}{" "}
                      in taxes
                    </div>
                    {checkoutTotals?.giftCard && (
                      <>
                        <div className="flex items-center justify-between">
                          <dt className="text-gray-600">
                            Gift card applied
                          </dt>
                          {!totalsLoading && (
                            <dd className="text-gray-900">
                              {formatPrice(
                                checkoutTotals.giftCard,
                                checkout.currencyCode,
                              )}
                            </dd>
                          )}
                          {totalsLoading && (
                            <dd>
                              <LoadingSpinner/>
                            </dd>
                          )}
                        </div>
                        {checkoutTotals?.amountDue && (
                          <div className="flex items-center justify-between">
                            <dt className="text-gray-600">Total</dt>
                            {!totalsLoading && (
                              <dd className="text-gray-900">
                                {formatPrice(
                                  checkoutTotals.amountDue,
                                  checkout.currencyCode,
                                )}
                              </dd>
                            )}
                            {totalsLoading && (
                              <dd>
                                <LoadingSpinner/>
                              </dd>
                            )}
                          </div>
                        )}
                      </>
                    )}
                  </dl>
                )}
              </div>
            </Transition.Child>
          </div>
        </Transition.Root>
      </div>
      <div className="relative mx-auto grid max-w-7xl grid-cols-1 gap-x-16 lg:grid-cols-2 lg:px-8 xl:gap-x-48">
        <h1 className="sr-only">Order information</h1>
        <section
          aria-labelledby="summary-heading"
          className="px-4 pb-10 pt-16 sm:px-6 lg:col-start-2 lg:row-start-1 lg:bg-transparent lg:px-0 lg:pb-16"
        >
          <div className="flex flex-col justify-center items-center lg:hidden py-16">
            <img
              src="/bushbuck-logo-square.png"
              alt="Bushbuck"
              className="cursor-pointer w-36 mb-3 rounded-md p-4 bg-gray-100 object-cover object-center"
              onClick={() => setShowDetails(true)}
            />
            <h2 className="text-lg font-medium text-gray-500 mb-2">
              Order complete
            </h2>
            <h3 className="text-3xl font-medium">
              Thank you!
            </h3>
            <button
              type="button"
              className="rounded-3xl mt-2 bg-gray-100 font-medium items-center gap-2 text-sm text-black flex px-6 py-3 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
              onClick={() => setShowDetails(true)}
            >
              View details
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="3"
                stroke="currentColor"
                className="w-3 h-3"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                />
              </svg>
            </button>
          </div>
          {!error && !loading && paymentData && (
            <div className="mx-auto max-w-lg lg:max-w-none">
              <h4 className="sr-only">Payment</h4>
              <dl className="grid grid-cols-2 gap-x-6 border-t border-gray-200 py-10 text-sm">
                <div>
                  <dt className="font-medium text-gray-900">Contact email</dt>
                  <dd className="mt-2 text-gray-700">
                    <p>{checkout.email}</p>
                  </dd>
                </div>
                <div>
                  <dt className="font-medium text-gray-900">Payment method</dt>
                  {paymentData.paymentMethod.card && (
                    <dd className="mt-2 text-gray-700">
                      <p>{paymentData.paymentMethod.card.brand}</p>
                      {paymentData.paymentMethod.card.last4 && (
                        <p>
                          <span aria-hidden="true">••••</span>
                          <span className="sr-only">Ending in </span>
                          {paymentData.paymentMethod.card.last4}
                        </p>
                      )}
                    </dd>
                  )}
                  {checkout?.appliedGiftCards.length > 0 && (
                    <dd className="mt-2 text-gray-700">
                      <p>Gift card</p>

                      {checkout.appliedGiftCards.map(giftCard => (
                        <p>
                          <span aria-hidden="true">{giftCard.lastCharacters}</span>
                          <span className="sr-only">Ending in </span>
                        </p>
                      ))}
                    </dd>
                  )}
                </div>
              </dl>
              <h4 className="sr-only">Shipping</h4>
              <dl className="grid grid-cols-2 gap-x-6 border-t border-gray-200 py-10 text-sm">
                <div>
                  <dt className="font-medium text-gray-900">
                    Shipping address
                  </dt>
                  <dd className="mt-2 text-gray-700">
                    <address className="not-italic">
                      {paymentData.shippingAddress &&
                        formatAddress(paymentData.shippingAddress)
                          .split(",")
                          .map((addressItem: string, index: number) => (
                            <span className="block" key={index}>
                              {addressItem}
                            </span>
                          ))}
                    </address>
                  </dd>
                </div>
                <div>
                  <dt className="font-medium text-gray-900">Shipping method</dt>
                  <dd className="mt-2 text-gray-700">
                    <p>{checkout.shippingMethod?.title}</p>
                  </dd>
                </div>
              </dl>
            </div>
          )}
        </section>
        <div
          className="hidden lg:flex flex-col px-4 pb-36 pt-16 sm:px-6 lg:col-start-1 lg:row-start-1 lg:px-0 lg:pb-16">
          <div className="flex items-center justify-flex-start mb-4 text-lg font-bold text-gray-500">
            <a
              href={config.storeUrl}
              aria-label="Bushbuck Logo"
              className={`flex group items-center justify-center cursor-pointer text-[#111] h-10`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-5 h-5 mr-2 text-gray-400 group-hover:text-gray-900"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                />
              </svg>
              <SvgLogo
                color="inherit"
                className="absolute left-16 transition-opacity duration-200 group-hover:opacity-0"
                style={{width: "140px", height: "36px", margin: "auto"}}
              />
              <span
                className="absolute text-sm left-16 leading-1 hidden duration-200 group-hover:inline transition-opacity">
                Back
              </span>
            </a>
          </div>
          <h3 className="text-lg font-bold text-gray-500 mb-2">
            Order complete
          </h3>
          <h2 className="text-4xl font-medium">Thank you!</h2>
          {checkout?.shippingAddress && (
            <div className="py-4">
              <StaticMap address={formatAddress(checkout.shippingAddress)}/>
            </div>
          )}
          {!checkout?.shippingAddress &&
            checkout?.customer?.preferredAddress && (
              <div className="py-4">
                <StaticMap
                  address={formatAddress(checkout.customer.preferredAddress)}
                />
              </div>
            )}
          <ul className="divide-y divide-gray-200 text-sm font-medium text-gray-900">
            {checkout?.lineItems.map((lineItem) => (
              <LineItem
                key={lineItem.variantId}
                lineItem={lineItem}
                currencyCode={checkout.currencyCode}
              />
            ))}
            {checkout?.bundleItems.map((bundleItem) => (
              <BundleItem
                key={bundleItem.id}
                bundle={bundleItem}
                currencyCode={checkout.currencyCode}
              />
            ))}
            {checkout?.giftItems.map((giftItem) => (
              <GiftItem
                key={giftItem.id}
                giftItem={giftItem}
                currencyCode={checkout.currencyCode}
              />
            ))}
            {checkout?.freeGiftTier && checkoutTotals && (parseFloat(checkoutTotals.total) > checkout?.freeGiftTier.thresholdAmount || checkout.freeGiftTier.isProductGift) && (
              <FreeGift
                freeGift={checkout?.freeGiftTier}
                currencyCode={checkout.currencyCode}
              />
            )}
          </ul>

          <dl className="hidden space-y-6 border-t border-gray-200 pt-6 text-sm font-medium text-gray-900 lg:block">
            <div className="flex items-center justify-between">
              <dt className="text-gray-600">Cart Total</dt>
              {!totalsLoading && checkoutTotals?.carttotal && (
                <dd>
                  {formatPrice(checkoutTotals.carttotal, checkout.currencyCode)}
                </dd>
              )}
              {totalsLoading && (
                <dd>
                  <LoadingSpinner/>
                </dd>
              )}
            </div>

            <div className="flex items-center justify-between">
              <dt className="text-gray-600">Savings</dt>
              {!totalsLoading && checkoutTotals?.discounts && (
                <dd className="text-gray-900">
                  {formatPrice(checkoutTotals.discounts, checkout.currencyCode)}
                </dd>
              )}
              {totalsLoading && (
                <dd>
                  <LoadingSpinner/>
                </dd>
              )}
            </div>

            <div className="flex items-center justify-between">
              <dt className="text-gray-600">Subtotal</dt>
              {!totalsLoading && checkoutTotals?.subtotal && (
                <dd>
                  {formatPrice(checkoutTotals.subtotal, checkout.currencyCode)}
                </dd>
              )}
              {totalsLoading && (
                <dd>
                  <LoadingSpinner/>
                </dd>
              )}
            </div>

            <div className="flex items-center justify-between">
              <dt className="text-gray-600">Shipping</dt>
              {!totalsLoading &&
                checkout.shippingMethod &&
                checkoutTotals?.shipping && (
                  <dd className="text-gray-900">
                    {formatPrice(
                      checkoutTotals.shipping,
                      checkout.currencyCode,
                    )}
                  </dd>
                )}
              {totalsLoading && (
                <dd>
                  <LoadingSpinner/>
                </dd>
              )}
              {!checkout.shippingMethod && (
                <dd className="text-gray-600">Enter shipping address</dd>
              )}
            </div>

            <div className="flex items-center justify-between border-t border-gray-200 pt-6">
              <dt className="text-base">Total</dt>
              {!totalsLoading && checkoutTotals?.total && (
                <dd className="text-gray-900">
                  {formatPrice(checkoutTotals.total, checkout.currencyCode)}
                </dd>
              )}
              {totalsLoading && (
                <dd>
                  <LoadingSpinner/>
                </dd>
              )}
            </div>
          </dl>
          {!totalsLoading && checkoutTotals?.tax && (
            <span className="text-sm text-gray-700 mt-3 font-light">
              including {formatPrice(checkoutTotals.tax, checkout.currencyCode)}{" "}
              in taxes
            </span>
          )}
          <div className="flex-1"></div>
          <div className="flex justify-center gap-10 mt-16">
            <div className="flex gap-1 text-sm items-center text-gray-400 font-light">
              <span>Powered by</span>
              <svg
                className="text-gray-500"
                fill="currentColor"
                focusable="false"
                width="33"
                height="15"
                role="img"
                aria-labelledby="stripe-title"
              >
                <title id="stripe-title">Stripe</title>
                <g fillRule="evenodd">
                  <path
                    d="M32.956 7.925c0-2.313-1.12-4.138-3.261-4.138-2.15 0-3.451 1.825-3.451 4.12 0 2.719 1.535 4.092 3.74 4.092 1.075 0 1.888-.244 2.502-.587V9.605c-.614.307-1.319.497-2.213.497-.876 0-1.653-.307-1.753-1.373h4.418c0-.118.018-.588.018-.804zm-4.463-.859c0-1.02.624-1.445 1.193-1.445.55 0 1.138.424 1.138 1.445h-2.33zM22.756 3.787c-.885 0-1.454.415-1.77.704l-.118-.56H18.88v10.535l2.259-.48.009-2.556c.325.235.804.57 1.6.57 1.616 0 3.089-1.302 3.089-4.166-.01-2.62-1.5-4.047-3.08-4.047zm-.542 6.225c-.533 0-.85-.19-1.066-.425l-.009-3.352c.235-.262.56-.443 1.075-.443.822 0 1.391.922 1.391 2.105 0 1.211-.56 2.115-1.39 2.115zM18.04 2.766V.932l-2.268.479v1.843zM15.772 3.94h2.268v7.905h-2.268zM13.342 4.609l-.144-.669h-1.952v7.906h2.259V6.488c.533-.696 1.436-.57 1.716-.47V3.94c-.289-.108-1.346-.307-1.879.669zM8.825 1.98l-2.205.47-.009 7.236c0 1.337 1.003 2.322 2.34 2.322.741 0 1.283-.135 1.581-.298V9.876c-.289.117-1.716.533-1.716-.804V5.865h1.716V3.94H8.816l.009-1.96zM2.718 6.235c0-.352.289-.488.767-.488.687 0 1.554.208 2.241.578V4.202a5.958 5.958 0 0 0-2.24-.415c-1.835 0-3.054.957-3.054 2.557 0 2.493 3.433 2.096 3.433 3.17 0 .416-.361.552-.867.552-.75 0-1.708-.307-2.467-.723v2.15c.84.362 1.69.515 2.467.515 1.879 0 3.17-.93 3.17-2.548-.008-2.692-3.45-2.213-3.45-3.225z"></path>
                </g>
              </svg>
            </div>
            <a
              className="text-gray-400 font-light text-sm hover:text-gray-900 underline"
              href={`${config.storeUrl}/pages/privacy-policy`}
            >
              Privacy
            </a>
            <a
              className="text-gray-400 font-light text-sm hover:text-gray-900 underline"
              href={`${config.storeUrl}/pages/returns-exchanges`}
            >
              Returns
            </a>
            <a
              className="text-gray-400 font-light text-sm hover:text-gray-900 underline"
              href={`${config.storeUrl}/pages/contact-us`}
            >
              Support
            </a>
          </div>
        </div>
      </div>
      <div className="flex lg:hidden justify-center gap-10">
        <a
          className="text-gray-400 font-light text-sm hover:text-gray-900 underline"
          href={`${config.storeUrl}/pages/privacy-policy`}
        >
          Privacy
        </a>
        <a
          className="text-gray-400 font-light text-sm hover:text-gray-900 underline"
          href={`${config.storeUrl}/pages/contact-us`}
        >
          Support
        </a>
      </div>
      <div className="flex gap-1 text-sm justify-center mt-4 items-center text-gray-400 font-light">
        <span>Powered by</span>
        <svg
          className="text-gray-500"
          fill="currentColor"
          focusable="false"
          width="33"
          height="15"
          role="img"
          aria-labelledby="stripe-title"
        >
          <title id="stripe-title">Stripe</title>
          <g fillRule="evenodd">
            <path
              d="M32.956 7.925c0-2.313-1.12-4.138-3.261-4.138-2.15 0-3.451 1.825-3.451 4.12 0 2.719 1.535 4.092 3.74 4.092 1.075 0 1.888-.244 2.502-.587V9.605c-.614.307-1.319.497-2.213.497-.876 0-1.653-.307-1.753-1.373h4.418c0-.118.018-.588.018-.804zm-4.463-.859c0-1.02.624-1.445 1.193-1.445.55 0 1.138.424 1.138 1.445h-2.33zM22.756 3.787c-.885 0-1.454.415-1.77.704l-.118-.56H18.88v10.535l2.259-.48.009-2.556c.325.235.804.57 1.6.57 1.616 0 3.089-1.302 3.089-4.166-.01-2.62-1.5-4.047-3.08-4.047zm-.542 6.225c-.533 0-.85-.19-1.066-.425l-.009-3.352c.235-.262.56-.443 1.075-.443.822 0 1.391.922 1.391 2.105 0 1.211-.56 2.115-1.39 2.115zM18.04 2.766V.932l-2.268.479v1.843zM15.772 3.94h2.268v7.905h-2.268zM13.342 4.609l-.144-.669h-1.952v7.906h2.259V6.488c.533-.696 1.436-.57 1.716-.47V3.94c-.289-.108-1.346-.307-1.879.669zM8.825 1.98l-2.205.47-.009 7.236c0 1.337 1.003 2.322 2.34 2.322.741 0 1.283-.135 1.581-.298V9.876c-.289.117-1.716.533-1.716-.804V5.865h1.716V3.94H8.816l.009-1.96zM2.718 6.235c0-.352.289-.488.767-.488.687 0 1.554.208 2.241.578V4.202a5.958 5.958 0 0 0-2.24-.415c-1.835 0-3.054.957-3.054 2.557 0 2.493 3.433 2.096 3.433 3.17 0 .416-.361.552-.867.552-.75 0-1.708-.307-2.467-.723v2.15c.84.362 1.69.515 2.467.515 1.879 0 3.17-.93 3.17-2.548-.008-2.692-3.45-2.213-3.45-3.225z"></path>
          </g>
        </svg>
      </div>
    </div>
  );
};
export default Confirm;
