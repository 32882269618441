import React from "react";
import { IFreeGiftTier } from "../types/types";
import { CurrencyCode } from "../types/storefront.generated";
import formatPrice from "../utils/formatPrice";

type FreeGiftProps = {
  freeGift: IFreeGiftTier;
  currencyCode: CurrencyCode;
};
const FreeGiftMobile = ({ freeGift, currencyCode }: FreeGiftProps) => {
  return (
    <li className="flex py-6">
      <div className="h-16 w-12 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
        {freeGift?.giftProduct.featuredImage.url && (
          <img
            src={freeGift?.giftProduct.featuredImage.url}
            alt={freeGift?.giftProduct.featuredImage.altText ?? ""}
            className={`h-full w-full object-cover object-center`}
          />
        )}
      </div>

      <div className="ml-4 flex flex-1 flex-col">
        <div>
          <div className="flex justify-between text-xs font-medium text-gray-900">
            <h3>
              {freeGift?.giftProduct.title}
            </h3>
            <div className="flex">
              <p className="ml-4 line-through">
                {formatPrice(freeGift.giftVariant.price.amount, currencyCode)}
              </p>
              <p className="ml-4">
                FREE
              </p>
            </div>
          </div>
          <p
            className={`font-light mt-1 text-sm text-gray-500`}
          >
            {freeGift.giftVariant.title}
          </p>
        </div>
        {freeGift.qualifiedQuantity > 0 && (
          <div className="flex flex-1 items-end justify-between text-xs">
            <p className="font-light text-gray-500">Qty {freeGift.qualifiedQuantity}</p>
          </div>
        )}
      </div>
    </li>
  )
    ;
}
export default FreeGiftMobile;
