import React, { useState } from 'react';
import { useCartContext } from "../CartContext";
import config from "../utils/config";
import LoadingSpinner from "./LoadingSpinner";

const CombinedCodeForm = () => {
  const { cartLoading, addDiscountCode, addGiftCard, removeGiftCard, checkout } = useCartContext();
  const [code, setCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showCodeForm, setShowCodeForm] = useState(false);
  const [errorMessage, setErrorMessage] = useState<String|null>(null);

  // Helper to normalize code (remove spaces, hyphens, make lowercase)
  const normalizeCode = (inputCode: string) => {
    return inputCode.replace(/[\s-]/g, '').toLowerCase();
  };

  // Check if input looks like a gift card code
  const isLikelyGiftCard = (inputCode: string) => {
    const normalized = normalizeCode(inputCode);
    // Gift cards are typically 16-18 hex chars
    const giftCardPattern = /^[a-f0-9]{16,18}$/i;
    return giftCardPattern.test(normalized);
  };

  async function handleApplyCode(event: React.MouseEvent | React.KeyboardEvent) {
    if (event) {
      event.preventDefault();
    }

    if (!code || code.trim() === '') {
      setErrorMessage('Please enter a discount code or gift card');
      return;
    }

    setIsLoading(true);
    setErrorMessage(null);

    const normalizedCode = normalizeCode(code);
    const likelyGiftCard = isLikelyGiftCard(normalizedCode);
    console.log(normalizedCode, likelyGiftCard);

    try {
      // Try the most likely method first based on pattern
      if (likelyGiftCard) {
        await tryGiftCard(normalizedCode);
      } else {
        await tryDiscountCode(normalizedCode);
      }
    } catch (e) {
      // If first attempt fails, try the other method
      try {
        if (likelyGiftCard) {
          await tryDiscountCode(normalizedCode);
        } else {
          await tryGiftCard(normalizedCode);
        }
      } catch (secondError) {
        setErrorMessage('Invalid code. Please check and try again.');
      }
    } finally {
      setIsLoading(false);
    }
  }

  async function tryGiftCard(normalizedCode: string) {
    const cartId = checkout?.cartId;
    if (!cartId) {
      throw new Error('Error adding gift card. Please try again.');
    }

    const response = await fetch(`${config.checkoutUrl}/api/validateGiftCard`, {
      method: "POST",
      body: JSON.stringify({
        code: normalizedCode,
      })
    });
    console.log(response);

    if (response.status === 200) {
      await addGiftCard(normalizedCode);
      setShowCodeForm(false);
      setCode('');
    } else {
      throw new Error('Invalid gift card');
    }
  }

  async function tryDiscountCode(normalizedCode: string) {
    await addDiscountCode(normalizedCode);
    setShowCodeForm(false);
    setCode('');
  }

  async function handleRemoveGiftCard() {
    await removeGiftCard();
  }

  async function handleRemoveDiscountCode() {
    await addDiscountCode('');
  }

  if (!checkout) {
    return (<></>);
  }

  const hasAppliedCode = checkout?.discountCode || (checkout?.appliedGiftCards && checkout.appliedGiftCards.length > 0);

  return (
    <>
      {!hasAppliedCode && !showCodeForm && (
        <button
          type="button"
          className="text-left text-blue-400 underline font-medium"
          onClick={() => setShowCodeForm(true)}
        >
          Add discount code or gift card
        </button>
      )}

      {!hasAppliedCode && showCodeForm && (
        <div>
          <label htmlFor="promo-code" className="block text-sm font-medium text-gray-700">
            Enter discount or gift card code
          </label>
          <div className="mt-1 flex space-x-4">
            <input
              type="text"
              value={code}
              onChange={(event) => {
                setErrorMessage(null);
                setCode(event.target.value ?? '');
              }}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  return handleApplyCode(event);
                }
              }}
              id="promo-code"
              name="promo-code"
              className="block w-full rounded-full border-gray-300 shadow-sm focus:border-grey-500 focus:ring-grey-500 sm:text-sm"
            />
            {!isLoading ? (
              <button
                type="button"
                disabled={cartLoading}
                onClick={handleApplyCode}
                className="rounded-full bg-gray-900 px-4 text-sm font-bold text-white hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-grey-500 focus:ring-offset-2 focus:ring-offset-gray-50 disabled:bg-gray-300"
              >
                Apply
              </button>
            ) : (
              <LoadingSpinner/>
            )}
            <button type="button" onClick={() => setShowCodeForm(false)}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                   stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12"/>
              </svg>
            </button>
          </div>
          {errorMessage && (
            <p className="mt-2 text-sm text-red-600" id="code-error">
              {errorMessage}
            </p>
          )}
        </div>
      )}

      {/* Display applied discount code */}
      {checkout?.discountCode && (
        <div className="flex items-center justify-flex-end">
          <div
            className="flex items-center shadow-sm justify-center align-center rounded-full bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                 stroke="currentColor" className="w-4 h-4 mr-1">
              <path strokeLinecap="round" strokeLinejoin="round"
                    d="M9.568 3H5.25A2.25 2.25 0 0 0 3 5.25v4.318c0 .597.237 1.17.659 1.591l9.581 9.581c.699.699 1.78.872 2.607.33a18.095 18.095 0 0 0 5.223-5.223c.542-.827.369-1.908-.33-2.607L11.16 3.66A2.25 2.25 0 0 0 9.568 3Z"/>
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 6h.008v.008H6V6Z"/>
            </svg>
            <span className="px-2">{checkout.discountCode}</span>
            <button type="button" onClick={handleRemoveDiscountCode} disabled={cartLoading}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                   stroke="currentColor" className="w-4 h-4">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12"/>
              </svg>
            </button>
          </div>
        </div>
      )}

      {/* Display applied gift cards */}
      {checkout?.appliedGiftCards && checkout.appliedGiftCards.map(giftCard => (
        <div key={giftCard.lastCharacters} className="flex items-center justify-flex-end">
          <div
            className="flex items-center shadow-sm justify-center align-center rounded-full bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                 stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round"
                    d="M21 11.25v8.25a1.5 1.5 0 0 1-1.5 1.5H5.25a1.5 1.5 0 0 1-1.5-1.5v-8.25M12 4.875A2.625 2.625 0 1 0 9.375 7.5H12m0-2.625V7.5m0-2.625A2.625 2.625 0 1 1 14.625 7.5H12m0 0V21m-8.625-9.75h18c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125h-18c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z"/>
            </svg>
            <span className="px-2">xxxx-xxxx-xxxx-{giftCard.lastCharacters}</span>
            <button type="button" onClick={handleRemoveGiftCard} disabled={cartLoading}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                   stroke="currentColor" className="w-4 h-4 mr-1">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12"/>
              </svg>
            </button>
          </div>
        </div>
      ))}
    </>
  );
};

export default CombinedCodeForm;
